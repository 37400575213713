import React from 'react'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const ColumnCommunityEdition = () => {
    return (
        <div className="bg-white overflow-hidden hover:bg-gray-100 border-l-2 border-gray-200">
            <div className="text-center">
                <div className="font-bold xl:text-xl p-2 text-center bg-bgColor">Commmunity Edition </div>
                <div className="grid grid-rows-3 text-center">
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">License Type<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/> </div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Support Ticket Response Time<br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Yearly subscription - USD <br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                    <div className="p-1 bg-bgColor text-bgColor">Limits</div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Concurrent Logins<br/></span>1</div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Max Devices<br/></span>Unlimited</div>
                    <div className="p-1 bg-bgColor text-bgColor">Features</div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Workspaces<br/></span>Max 2</div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Screens *<br/></span>Max 2</div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Import/Export function <br/></span><CancelIcon style={{fontSize:'20px'}} className="text-disabledColor"/></div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Proxy setup for each Workstation<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Hibernation Function<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Java script injection<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Light/Dark mode<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Graphical memory usage monitoring<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Profile manager<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Integrated Offline Password manager<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Toolbox - Notes<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Toolbox - To do Lists<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Toolbox - Diary<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Toolbox – History audit track<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Toolbox - Translator<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Toolbox – Notification panel<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Toolbox - Email<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div>
                    <div className="p-1"><span className="sm:block font-medium text-base lg:hidden">Toolbox - Downloads<br/></span><CheckCircleIcon style={{fontSize:'20px'}} className="text-checkColor"/></div> 
                </div>
            </div>
        </div>
    )

}
export default ColumnCommunityEdition