import React from 'react'

const ColumnTitles = () => {
    return (
        <div className="bg-white hidden col-span-2 lg:block overflow-hidden hover:bg-gray-100 rounded-tl-lg rounded-bl-lg">
            <div className="text-justify">
                <div className="font-bold xl:text-xl p-2 text-center bg-bgColor rounded-tl-lg">License </div>
                <div className="grid grid-rows-3">
                    <div className="p-1">License Type</div>
                    <div className="p-1">Support Ticket Response Time</div>
                    <div className="p-1">Yearly subscription USD </div>
                    <div className=" font-semibold p-1 bg-bgColor">Limits</div>
                    <div className=" p-1">Concurrent logins</div>
                    <div className="p-1">Max Devices</div>
                    <div className=" font-semibold p-1 bg-bgColor">Features</div>
                    <div className=" p-1">Workspaces</div>
                    <div className=" p-1">Screens *</div>
                    <div className=" p-1">Import/Export function</div>
                    <div className=" p-1">Proxy setup for each Workstation</div>
                    <div className=" p-1">Hibernation Function</div>
                    <div className=" p-1">Java script injection</div>
                    <div className="lg:text-sm xl:text-base p-1">Light/Dark mode</div>
                    <div className=" p-1">Graphical memory usage monitoring</div>
                    <div className=" p-1">Profile manager</div>
                    <div className=" p-1">Integrated Offline Password manager</div>
                    <div className=" p-1">Toolbox - Notes</div>
                    <div className=" p-1">Toolbox - To do Lists</div>
                    <div className=" p-1">Toolbox - Diary</div>
                    <div className=" p-1">Toolbox – History audit track</div>
                    <div className=" p-1">Toolbox - Translator</div>
                    <div className=" p-1">Toolbox – Notification panel</div>
                    <div className=" p-1">Toolbox - Email</div>
                    <div className=" p-1">Toolbox - Downloads</div>
                </div>
            </div>
        </div>
    )
}
export default ColumnTitles